import { HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AbstractControl } from '@angular/forms'

@Injectable({
   providedIn: 'root',
})
export class UtilityService {
   public noWhitespaceValidator(control: AbstractControl) {
      return (control.value || '').trim().length ? null : { whitespace: true }
   }

   public downloadFile(response: HttpResponse<Blob>) {
      if (!response.body) {
         console.error('cannot download empty body response')
         return
      }

      let type = response.headers.get('content-type') ?? 'application/json'
      let fileName = response.headers.get('content-disposition')?.split('filename=')[1] ?? 'no-file-name'

      let blob = new Blob([response.body], { type: type })
      let downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.setAttribute('download', fileName)
      document.body.appendChild(downloadLink)
      downloadLink.click()
   }

   public isFieldValuePresentInArray(array: any[], fieldName: string, value: any): boolean {
      return array.some((el) => el[fieldName] == value)
   }

   public createMatrixFromArray(array: any[], elementsNum: 2 | 3): any[][] {
      let matrix: any[][] = []
      if (array.length == 0) return matrix

      for (let y = 0; y < elementsNum; y++) {
         matrix.push([])
      }

      let count = 1

      for (let i = 0; i < array.length; i++) {
         let el = array[i]
         for (let x = 0; x < matrix.length; x++) {
            if (matrix[x].length < count) {
               matrix[x].push(el)
               if (x == matrix.length - 1) {
                  count++
               }
               break
            }
         }
      }
      return matrix
   }
}
